import request from '@/utils/request'
import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//配置list
export function enumList(params) {
	return loginRequest({
		url: '/frontend/website/bingo/enum-list',
		method: 'post',
		data: Qs.stringify(params)
	})
}

export function chinacity(params) {
	return loginRequest({
		url: '/frontend/website/china-city/list',
		method: 'post',
		data: Qs.stringify(params)
	})
}


export function addBingo(params) {
	return request({
		url: '/frontend/website/bingo/add',
		method: 'post',
		data: Qs.stringify(params)
	})
}

export function listBingo(params) {
	return request({
		url: '/frontend/website/bingo/list',
		method: 'post',
		data: Qs.stringify(params)
	})
}



//列表居间代理
export function bingoHomeList(params) {
	return loginRequest({
		url: '/frontend/website/bingo/home-list',
		method: 'post',
		data: Qs.stringify(params)
	})
}


//列表居间代理
export function bingoHomeListForLogin(params) {
	return loginRequest({
		url: '/frontend/website/bingo/home-list',
		method: 'post',
		data: Qs.stringify(params)
	})
}




//农地流转首页列表
export function nongHomeList(params) {
	return loginRequest({
		url: '/frontend/website/bingo/home-nong-list',
		method: 'post',
		data: Qs.stringify(params)
	})
}


//并购热门城市
export function hotCity(params) {
	return loginRequest({
		url: '/frontend/website/bingo/hot-city',
		method: 'post',
		data: Qs.stringify(params)
	})
}

//并购详情页
export function bingoDetail(params) {
	return loginRequest({
		url: '/frontend/website/bingo/detail',
		method: 'post',
		data: Qs.stringify(params)
	})
}




//广告
export function ad(params) {
	return loginRequest({
		url: '/frontend/website/bingo/ad',
		method: 'post',
		data: Qs.stringify(params)
	})
}



//广告
export function detail(params) {
	return loginRequest({
		url: '/frontend/website/bingo/detail',
		method: 'post',
		data: Qs.stringify(params)
	})
}



//申请资料
export function apply(params) {
	return request({
		url: '/frontend/website/bingo/data-apply',
		method: 'post',
		data: Qs.stringify(params)
	})
}




//申请资料
// export function loginedApply(params) {
// 	return request({
// 		url: '/frontend/website/bingo/data-apply',
// 		method: 'post',
// 		data: Qs.stringify(params)
// 	})
// }

